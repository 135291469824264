import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                About Home + Life Insurance
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] &&
                                                                <>
                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 3 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> Won't it be too expensive to
                                                                                                buy home and life insurance together? </div><img src={showResults[3] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="8" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[3] && `If you buy life insurance
                                                                                            alongside home insurance while you're in good health and relatively
                                                                                            young, you can lock in your premium for decades making it quite
                                                                                            affordable to team it up with home insurance.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 4 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> What if I just need one of
                                                                                                either home or life insurance? </div><img src={showResults[4] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="9" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[4] && `Sure, you can buy either
                                                                                            one. But we strongly advise you to bundle them together for well-rounded
                                                                                            protection for your loved ones.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 5 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> Can I buy home insurance now
                                                                                                and add life insurance later? </div><img src={showResults[5] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="17" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[5] && `Sure, you can buy one kind
                                                                                            of insurance now and the other kind at a later time. But it won't be
                                                                                            bundled together. It's best to buy them together to enjoy complete peace
                                                                                            of mind.`}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6 col-12">
                                                                    <div className="faqSubContainer">
                                                                      <div className="faqFlex faq-container-align">
                                                                        <div className="faqLeftContainer row">
                                                                          <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 6 )}>
                                                                            <div className="align-faq-que-box position-relative">
                                                                              <div className="carrier-faq carrier-faq-width"> What are the advantages of
                                                                                                buying Home and Life insurance together? </div><img src={showResults[6] ? UpArrow : DownArrow}
                                                                                className="faq-dd faq-dd-width m-0" name="51" alt="Bubble Life Insurance" />
                                                                            </div>

                                                                            <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[6] && `You need home insurance to
                                                                                            protect your home and your personal belongings. Life insurance does the
                                                                                            same for your family when you are not around anymore. Buying them
                                                                                            together means being prepared for any contingency.
                                                                                        `}</p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
